<template>
    <v-row v-if="loading" class="ma-0">
        <v-col cols="3" class="pl-2 pr-0 pt-4 pb-2">
            <v-skeleton-loader type="text" width="40%" height="20" />
        </v-col>
        <v-col cols="9" class="pl-2 pr-0 pt-4 pb-2">
            <v-skeleton-loader type="text" width="35%" height="20" />
        </v-col>
    </v-row>
    <v-row
        v-else
        class="caption text-uppercase ma-0"
        :class="{
            'px-2': $vuetify.breakpoint.mdAndUp
        }"
    >
        <v-col cols="3" class="pl-2 pr-0">Ranking</v-col>
        <v-col cols="9" class="pl-2 pr-0">Keyword</v-col>
    </v-row>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

const KeywordsListHeaderProps = Vue.extend({
    name: 'KeywordsListHeader',
    props: {
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component
export default class KeywordsListtHeader extends KeywordsListHeaderProps {}
</script>
