import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c('report-top-banner',{staticClass:"mt-4 mb-5"}),_c(VRow,{staticClass:"mt-2 mb-0 px-3"},[_c(VCol,{staticClass:"px-0",class:{ 'pt-2': _vm.$vuetify.breakpoint.smAndDown },attrs:{"cols":"12","md":"3","order":"2","order-md":"1"}},[_c('div',{staticClass:"text-h6 text-md-h5 font-weight-bold",class:{
                    'text-center pt-3 mb-n2': _vm.$vuetify.breakpoint.smAndDown
                }},[_vm._v(" Mentions Report ")])]),_c(VCol,{staticClass:"px-0",class:{ 'pa-0': _vm.$vuetify.breakpoint.smAndDown },attrs:{"cols":"12","md":"9","order":"1","order-md":"2"}},[_c(VBtn,{staticClass:"back-button",attrs:{"color":"white","elevation":"0","title":"Back to Amp","block":_vm.$vuetify.breakpoint.smAndDown,"to":_vm.ampLink}},[_c(VIcon,{attrs:{"small":"","left":""}},[_vm._v("arrow-left")]),_vm._v(" Back to Amp ")],1)],1)],1),_c(VRow,{staticClass:"report-container",attrs:{"dense":""}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c(VCol,{attrs:{"cols":"12","md":"3"}},[_c('sticky-media',{attrs:{"relative-element-selector":".report-container","offset":{ top: 90, bottom: 90 },"enabled":!_vm.$vuetify.breakpoint.mobile}},[_c(VList,{staticClass:"main-background pa-0",class:{ 'pr-4': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"nav":""}},[_c(VListItemGroup,{model:{value:(_vm.activeSection),callback:function ($$v) {_vm.activeSection=$$v},expression:"activeSection"}},[_vm._l((_vm.sections),function(section){return [(section.sections)?_c(VMenu,{key:section.to,attrs:{"min-width":"180","open-on-hover":"","internal-activator":"","right":"","offset-x":"","nudge-top":"8"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs, value }){return [_c(VListItem,_vm._g(_vm._b({staticClass:"justify-center",class:{ menu__open: value },attrs:{"dense":_vm.$vuetify.breakpoint.smAndDown},on:{"click":function($event){return _vm.action(section)}}},'v-list-item',attrs,false),on),[_c(VListItemIcon,{staticClass:"justify-center align-center",class:{
                                                'mr-3': _vm.$vuetify.breakpoint
                                                    .smAndDown,
                                                'pl-2 mr-4':
                                                    _vm.$vuetify.breakpoint
                                                        .mdAndUp
                                            }},[_c(VIcon,{staticStyle:{"width":"20px"},attrs:{"small":""},domProps:{"textContent":_vm._s(section.icon)}})],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-h7 text-wrap",domProps:{"textContent":_vm._s(section.title)}})],1)],1)]}}],null,true)},[_c(VList,_vm._l((section.sections),function(subSection){return _c(VListItem,{key:subSection.to,on:{"click":function($event){return _vm.action(subSection)}}},[_c(VListItemTitle,[_vm._v(" "+_vm._s(subSection.title)+" ")])],1)}),1)],1):_c(VListItem,{key:section.to,staticClass:"justify-center",attrs:{"dense":_vm.$vuetify.breakpoint.smAndDown},on:{"click":function($event){return _vm.action(section)}}},[_c(VListItemIcon,{staticClass:"justify-center align-center",class:{
                                        'mr-3': _vm.$vuetify.breakpoint
                                            .smAndDown,
                                        'pl-2 mr-4':
                                            _vm.$vuetify.breakpoint.mdAndUp
                                    }},[_c(VIcon,{staticStyle:{"width":"20px"},attrs:{"small":""},domProps:{"textContent":_vm._s(section.icon)}})],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-h7 text-wrap",domProps:{"textContent":_vm._s(section.title)}})],1)],1)]})],2)],1)],1)],1):_vm._e(),_c(VCol,{staticClass:"report-column",class:{ 'mb-14': _vm.$vuetify.breakpoint.smAndDown },attrs:{"cols":"12","md":"9"}},[_c('distribution-report',{attrs:{"report":_vm.report,"loading":_vm.isLoading},on:{"intersect":_vm.onIntersect,"actions":_vm.setActions}}),_c('report-bottom-banner',{staticClass:"mt-4"})],1)],1),(_vm.$vuetify.breakpoint.smAndDown)?_c(VBottomNavigation,{staticClass:"bottom-navigation",attrs:{"fixed":"","hide-on-scroll":"","scroll-threshold":"400"},model:{value:(_vm.activeSection),callback:function ($$v) {_vm.activeSection=$$v},expression:"activeSection"}},_vm._l((_vm.sections),function(section){return _c(VBtn,{key:section.to,on:{"click":function($event){return _vm.action(section)}}},[_c('span',{staticClass:"pt-1"},[_vm._v(_vm._s(section.alt || section.title))]),_c(VIcon,{attrs:{"small":""},domProps:{"textContent":_vm._s(section.icon)}})],1)}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }