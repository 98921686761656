<template>
    <v-row v-if="loading" class="ma-0">
        <v-col cols="7" class="pl-2 pr-0 pt-4 pb-2">
            <v-skeleton-loader type="text" width="40%" height="20" />
        </v-col>
        <v-col cols="3" class="pl-2 pr-0 pt-4 pb-2">
            <v-skeleton-loader type="text" width="35%" height="20" />
        </v-col>
        <v-col cols="2" class="pl-2 pr-0 pt-4 pb-2 d-flex justify-center">
            <v-skeleton-loader type="text" width="60%" height="20" />
        </v-col>
    </v-row>
    <v-row v-else class="caption text-uppercase ma-0">
        <v-col cols="7" class="pl-2 pr-0">Domain</v-col>
        <v-col
            cols="3"
            class="pl-2 pr-0"
            :class="{ 'text-center': $vuetify.breakpoint.smAndDown }"
        >
            Media type
        </v-col>
        <v-col cols="2" class="pl-2 pr-0 text-center text-truncate">
            Authority
        </v-col>
    </v-row>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

const BrandMentionsListHeaderProps = Vue.extend({
    name: 'BrandMentionsListHeader',
    props: {
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component
export default class BrandMentionsListHeader extends BrandMentionsListHeaderProps {}
</script>
