<template>
    <v-container v-if="isBusy">
        <brand-mentions-list-header :loading="loading" />
        <brand-mention-row
            v-for="mention in renderableItems.length || 10"
            :key="'mention-row-' + mention"
            loading
            :distribution-type="distributionType"
        />
    </v-container>
    <v-container v-else-if="hasData" id="mentions-container">
        <brand-mentions-list-header />
        <brand-mention-row
            v-for="(mention, index) in renderableItems"
            :key="'mention-row-' + index"
            :mention="mention"
            :distribution-type="distributionType"
        />
        <v-row>
            <v-col class="text-center caption tertiary--text">
                These rankings are a snapshot from shortly after the Amp was
                published and will change over time
            </v-col>
        </v-row>
        <v-row v-if="hasMore">
            <v-col class="text-center">
                <v-btn color="white" @click="toggleAll">
                    {{ toggleButtonLabel }}
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
    <a-message-bar v-else>{{ emptyListLabel }}</a-message-bar>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { getScrollParent } from '@/utils/helpers';

import { AMessageBar } from '@/components/AMessageBar';

import { MentionMediaTypes } from '..';

import BrandMentionsListHeader from './BrandMentionsListHeader.vue';
import BrandMentionRow from './BrandMentionRow.vue';

import type { Distribution, DistributionType } from '@/types/Report';

const BrandMentionsListProps = Vue.extend({
    name: 'BrandMentionsList',
    props: {
        mentions: {
            type: Array as PropType<Distribution[]>,
            default() {
                return [];
            }
        },
        distributionType: {
            type: Object as PropType<DistributionType>,
            default() {
                return null;
            }
        },
        loading: {
            type: Boolean,
            default() {
                return true;
            }
        },
        updating: {
            type: Boolean,
            default() {
                return false;
            }
        },
        limit: {
            type: String,
            default() {
                return '';
            }
        },
        filter: {
            type: String,
            default() {
                return '';
            }
        }
    }
});

@Component<BrandMentionsList>({
    components: {
        AMessageBar,
        BrandMentionsListHeader,
        BrandMentionRow
    },
    watch: {
        filter() {
            this.stream = [];
        }
    }
})
export default class BrandMentionsList extends BrandMentionsListProps {
    renderAll = false;

    stream: Distribution[] = [];

    get hasData() {
        return Boolean(this.filteredMentions.length);
    }

    get isBusy() {
        return this.loading || this.updating;
    }

    get limitAmount() {
        return this.limit ? Number(this.limit) : 0;
    }

    get hasMore() {
        return (
            this.limitAmount && this.filteredMentions.length > this.limitAmount
        );
    }

    get renderableItems() {
        return this.streamAll();
    }

    get toggleButtonLabel() {
        return this.renderAll ? `See the compact view` : 'Review all Mentions';
    }

    get filteredMentions() {
        if (this.filter) {
            // we can have complex filters, like "VideoPrs,Movies"
            const filter = this.filter.split(',');

            return this.mentions.filter(({ type }) => filter.includes(type));
        }

        return this.mentions;
    }

    get filterTypeLabel() {
        if (this.filter) {
            if (MentionMediaTypes[this.filter]) {
                return MentionMediaTypes[this.filter].label;
            }

            return MentionMediaTypes.PressReleases.label;
        }

        return '';
    }

    get emptyListLabel() {
        return `Your brand has no ${[this.filterTypeLabel, 'mentions']
            .filter(Boolean)
            .join(' ')} yet`;
    }

    streamAll() {
        if (this.stream.length) {
            return this.stream;
        }

        if (this.limitAmount) {
            if (this.renderAll) {
                this.streamItems([...this.filteredMentions]);
            }

            return this.filteredMentions.slice(0, this.limitAmount);
        } else {
            return this.filteredMentions;
        }
    }
    // fake loading simulation
    async streamItems(items: Distribution[]) {
        this.setLoading();

        while (items.length) {
            await new Promise(resolve => {
                setTimeout(() => {
                    this.stream.push(...items.splice(0, this.limitAmount));

                    resolve(true);
                }, 50);
            });
        }

        this.setLoading(false);
    }

    setLoading(isLoading = true) {
        this.$emit('loading', isLoading);

        this.$store.dispatch(isLoading ? 'loading/show' : 'loading/hide');
    }

    toggleAll() {
        if (this.renderAll) {
            this.stream = [];

            this.renderAll = !this.renderAll;

            this.$nextTick(() => {
                this.scrollToTop();
            });
        } else {
            this.renderAll = !this.renderAll;
        }
    }

    scrollToTop() {
        const element = document.getElementById('mentions-container');

        if (element) {
            const container = getScrollParent(element) as HTMLElement;

            if (container) {
                this.$vuetify.goTo(element, {
                    container,
                    offset: 20
                });
            }
        }
    }
}
</script>
