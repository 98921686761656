<template>
    <v-container v-if="isBusy">
        <keywords-list-header :loading="loading" />
        <keyword-row
            v-for="keyword in renderableItems.length || 10"
            :key="'keyword-row-' + keyword"
            loading
        />
    </v-container>
    <v-container v-else-if="hasData">
        <div :class="{ multicolumns: $vuetify.breakpoint.mdAndUp }">
            <keywords-list-header />
            <keywords-list-header v-if="$vuetify.breakpoint.mdAndUp" />
        </div>
        <div :class="{ multicolumns: $vuetify.breakpoint.mdAndUp }">
            <keyword-row
                v-for="(keyword, index) in renderableItems"
                :key="'keyword-row-' + index"
                :keyword="keyword"
            />
        </div>
    </v-container>
    <a-message-bar v-else>
        Your brand has no keywords yet. Keywords are typically posted within 24
        hours of your news article being published.
    </a-message-bar>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import { AMessageBar } from '@/components/AMessageBar';

import KeywordsListHeader from './KeywordsListHeader.vue';
import KeywordRow from './KeywordRow.vue';

import type { KeywordRank, Report } from '@/types/Report';

const KeywordsListProps = Vue.extend({
    name: 'KeywordsList',
    props: {
        keywords: {
            type: Object as PropType<Report['keywords']>,
            default(): Report['keywords'] {
                return {};
            }
        },
        loading: {
            type: Boolean,
            default() {
                return true;
            }
        },
        updating: {
            type: Boolean,
            default() {
                return false;
            }
        },
        limit: {
            type: [String, Number],
            default() {
                return null;
            }
        }
    }
});

@Component({
    components: {
        AMessageBar,
        KeywordsListHeader,
        KeywordRow
    }
})
export default class KeywordsList extends KeywordsListProps {
    isRendering = false;

    get hasData() {
        return Boolean(this.renderableItems.length);
    }

    get isBusy() {
        return this.loading || this.updating || this.isRendering;
    }

    get renderableItems() {
        const list = Object.keys(this.keywords || {}).reduce(
            (acc: KeywordRank[], keyword: string) => {
                acc.push(this.keywords[keyword]);
                return acc;
            },
            []
        );

        if (this.limit) {
            return list.slice(0, Number(this.limit));
        }

        return list;
    }
}
</script>

<style lang="scss" scoped>
.multicolumns {
    column-count: 2;
}
</style>
