export { default } from './BrandMentions.vue';

export const MentionMediaTypes: Record<
    string,
    { label: string; icon: string }
> = {
    PressReleases: {
        label: 'News',
        icon: 'file-alt'
    },
    BlogPosts: {
        label: 'Blog',
        icon: 'pen-square'
    },
    Podcasts: {
        label: 'Podcast',
        icon: 'headphones'
    },
    Slideshows: {
        label: 'Slideshow',
        icon: 'tv'
    },
    Infographics: {
        label: 'Infographic',
        icon: 'file-image'
    },
    'VideoPrs,Movies,Tweets,Social': {
        label: 'Social & Video',
        icon: 'play-circle'
    },
    GuestPosts: {
        label: 'B.L.O.W.',
        icon: 'pen-nib'
    }
};
