import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.loading)?_c(VRow,{staticClass:"ma-0"},[_c(VCol,{staticClass:"pl-2 pr-0 pt-4 pb-2",attrs:{"cols":"3"}},[_c(VSkeletonLoader,{attrs:{"type":"text","width":"40%","height":"20"}})],1),_c(VCol,{staticClass:"pl-2 pr-0 pt-4 pb-2",attrs:{"cols":"9"}},[_c(VSkeletonLoader,{attrs:{"type":"text","width":"35%","height":"20"}})],1)],1):_c(VRow,{staticClass:"caption text-uppercase ma-0",class:{
        'px-2': _vm.$vuetify.breakpoint.mdAndUp
    }},[_c(VCol,{staticClass:"pl-2 pr-0",attrs:{"cols":"3"}},[_vm._v("Ranking")]),_c(VCol,{staticClass:"pl-2 pr-0",attrs:{"cols":"9"}},[_vm._v("Keyword")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }