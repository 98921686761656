import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{class:{ 'pa-0': _vm.$vuetify.breakpoint.smAndDown }},[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{attrs:{"color":"primary","loading":_vm.isDownloading},on:{"click":_vm.downloadPdf}},[_c(VIcon,{attrs:{"left":"","small":""}},[_vm._v("file-pdf")]),_vm._v(" Download PDF Report ")],1)],1),_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{attrs:{"loading":_vm.isDownloading},on:{"click":_vm.downloadCsv}},[_c(VIcon,{attrs:{"left":"","small":""}},[_vm._v("file-csv")]),_vm._v(" Download CSV Report ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }