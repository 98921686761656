import { VCol } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.loading)?_c(VRow,{staticClass:"a-list-row ma-0 mb-1 report-loading-row"},[_c(VCol,{staticClass:"pl-2 pr-0 ma-auto",attrs:{"cols":"7"}},[_c(VSkeletonLoader,{attrs:{"type":"text","width":_vm.randomBetween(25, 75) + '%'}})],1),_c(VCol,{staticClass:"pl-2 pr-0 ma-auto",attrs:{"cols":"3"}},[_c(VSkeletonLoader,{attrs:{"type":"text","width":_vm.randomBetween(35, 55) + '%'}})],1),_c(VCol,{staticClass:"pl-2 pr-0 ma-auto d-flex justify-center",attrs:{"cols":"2"}},[_c(VSkeletonLoader,{attrs:{"type":"text","width":_vm.randomBetween(20, 35) + '%'}})],1)],1):_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c(VRow,{staticClass:"a-list-row ma-0 mb-1 report-row",class:{
            white: !hover,
            'concrete-light': hover,
            small: _vm.$vuetify.breakpoint.smAndDown
        }},[_c(VCol,{staticClass:"pl-2 pr-0 ma-auto d-inline-flex",attrs:{"cols":"7"}},[_c('a',{staticClass:"text-truncate",style:(_vm.favicon),attrs:{"target":"_blank","href":_vm.mention.url,"data-favicon":""}},[_c('text-truncated',{attrs:{"text":_vm.mention.domain}})],1),(_vm.isSpecialSite)?_c('status-chip',{staticClass:"mx-2",attrs:{"status":_vm.status,"label":_vm.statusLabel,"tooltip":_vm.statusTooltip}}):_vm._e()],1),_c(VCol,{staticClass:"pl-2 pr-0 ma-auto d-flex align-center",class:{ 'justify-center': _vm.$vuetify.breakpoint.smAndDown },attrs:{"cols":"3"}},[_c(VIcon,{attrs:{"small":"","left":""}},[_vm._v(_vm._s(_vm.moduleTypeIcon))]),_vm._v(" "+_vm._s(_vm.moduleTypeLabel)+" ")],1),_c(VCol,{staticClass:"pl-2 pr-0 ma-auto text-center",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.authority)+" ")])],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }