<template>
    <v-container :class="{ 'pa-0': $vuetify.breakpoint.smAndDown }">
        <label class="text-h6 d-flex align-center justify-center">
            {{ headerTitle }}
            <v-menu
                offset-y
                close-delay="100"
                transition="scale-transition"
                :open-on-hover="!$vuetify.breakpoint.mobile"
                :left="$vuetify.breakpoint.smAndDown"
                :bottom="$vuetify.breakpoint.smAndDown"
                :max-width="$vuetify.breakpoint.smAndDown ? 'auto' : '20vw'"
                :origin="
                    $vuetify.breakpoint.mdAndUp ? 'bottom left' : 'top right'
                "
                :offset-x="$vuetify.breakpoint.mdAndUp"
                :right="$vuetify.breakpoint.mdAndUp"
                :top="$vuetify.breakpoint.mdAndUp"
            >
                <template #activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                        <v-icon
                            small
                            right
                            :color="hover ? 'secondary' : 'tertiary'"
                            v-bind="attrs"
                            v-on="on"
                        >
                            circle-info
                        </v-icon>
                    </v-hover>
                </template>
                <div class="secondary--text body-2 px-6 py-4">
                    The purpose of distributing news or other media data is to
                    land coverage in media publications. That way, you're
                    positioning your brand in front of a wider audience. Gaining
                    more comprehensive coverage helps get the business or brand
                    name in front of the people and helps build brand awareness.
                </div>
            </v-menu>
        </label>
        <v-row v-if="hasKeywords">
            <v-col>
                <!-- we cannot put that progress into the keywords-cloud, as it will keep updating the component causing cloud re-render => infinite loop -->
                <v-progress-linear
                    :value="progress"
                    class="cloud-progress mt-4 mb-n4"
                    rounded
                    :class="{ 'is-loading': isLoading }"
                />
                <keywords-cloud
                    :loading="loading"
                    :keywords="keywords"
                    :limit="cloudSize"
                    :group="groupSize"
                    @progress="onProgress"
                    @select="openPhraseSearch"
                />
                <v-divider v-if="!isLoading && !renderAll" class="mt-6" />
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col>
                <a-message-bar>
                    Your brand has no keywords yet. Keywords are typically
                    posted within 2 weeks of your news article being published.
                </a-message-bar>
            </v-col>
        </v-row>
        <v-row v-if="renderAll">
            <v-col>
                <keywords-list
                    :loading="loading"
                    :keywords="keywords"
                    class="pa-0"
                />
            </v-col>
        </v-row>
        <v-row v-if="!isLoading">
            <v-col class="text-center caption tertiary--text">
                These rankings are a snapshot from the time after Amp went live.
                These rankings are subject to change over time.
            </v-col>
        </v-row>
        <v-row v-if="hasMore && !isLoading">
            <v-col class="text-center">
                <v-btn color="white" @click="toggleAll">
                    {{ toggleButtonLabel }}
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import { AMessageBar } from '@/components/AMessageBar';

import KeywordsCloud from './KeywordsCloud/KeywordsCloud.vue';
import KeywordsList from './List/KeywordsList.vue';

import type { Report } from '@/types/Report';
import { getScrollParent } from '@/utils/helpers';

const BrandKeywordsProps = Vue.extend({
    name: 'BrandKeywords',
    props: {
        keywords: {
            type: Object as PropType<Report['keywords']>,
            default() {
                return {};
            }
        },
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        AMessageBar,
        KeywordsCloud,
        KeywordsList
    }
})
export default class BrandKeywords extends BrandKeywordsProps {
    progress = 0;

    cloudSize = 24;
    groupSize = 6;

    renderAll = false;

    get isLoading() {
        return this.progress < 100;
    }

    get hasKeywords() {
        return Boolean(this.keywordsCount);
    }

    get keywordsCount() {
        return Object.keys(this.keywords || {}).length;
    }

    get headerTitle() {
        return [!this.loading && this.keywordsCount, 'Keywords Targeted']
            .filter(Boolean)
            .join(' ');
    }

    get hasMore() {
        return this.keywordsCount > this.cloudSize;
    }

    get toggleButtonLabel() {
        return this.renderAll
            ? `Hide detailed list`
            : 'Review all Search Phrases';
    }

    onProgress(v: number) {
        this.progress = v;
    }

    toggleAll() {
        this.renderAll = !this.renderAll;

        if (!this.renderAll) {
            this.$nextTick(() => {
                this.scrollToTop();
            });
        }
    }

    openPhraseSearch(phrase: string) {
        Object.assign(document.createElement('a'), {
            target: '_blank',
            rel: 'noopener noreferrer',
            href: `https://www.google.com/search?q=${phrase}`
        }).click();
    }

    scrollToTop() {
        const element = document.getElementById('keywords');

        if (element) {
            const container = getScrollParent(element) as HTMLElement;

            if (container) {
                this.$vuetify.goTo(element, {
                    container,
                    offset: 20
                });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
[role='menu'] {
    background-color: $white;
    box-shadow: 0px 5px 10px -5px $light-black;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    opacity: 1;
}

.cloud-progress {
    opacity: 0;

    &.is-loading {
        opacity: 1;
    }
}
</style>
