import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{class:{ 'pa-0': _vm.$vuetify.breakpoint.smAndDown }},[_c(VRow,[_c(VCol,{staticClass:"px-6"},[_c('div',{staticClass:"report-banner"},[_c(VContainer,[_c(VRow,{staticClass:"flex-row-reverse",class:{
                            ' justify-center': _vm.$vuetify.breakpoint.smAndDown
                        }},[_c(VCol,{staticClass:"banner-action d-flex justify-center align-center flex-column text-center pa-4",attrs:{"cols":"10","md":"4"}},[_c('span',{staticClass:"text-subtitle-1"},[_vm._v(" Professional Amp Authors are available to create your next amp ")]),_c(VBtn,{staticClass:"mt-3 primary--text",attrs:{"color":"white","elevation":"0","title":"Learn More","to":"/author-requests/create"}},[_vm._v(" Learn More "),_c(VIcon,{attrs:{"small":"","right":""}},[_vm._v("arrow-right")])],1)],1)],1)],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }