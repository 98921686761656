<template>
    <v-container :class="{ 'pa-0': $vuetify.breakpoint.smAndDown }">
        <v-row v-if="isFilterable" class="justify-end">
            <v-col cols="12" md="5" xl="4" class="d-flex align-end pb-4">
                <v-autocomplete
                    v-model="filterByType"
                    placeholder="Filter By Type"
                    :items="typeFilterItems"
                    :loading="isBusy"
                    :disabled="isBusy"
                    clearable
                    hide-details
                    hide-no-data
                    hide-selected
                    class="reduced-size"
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <brand-mentions-list
                    limit="10"
                    class="px-0"
                    :loading="loading"
                    :mentions="mentions"
                    :distribution-type="distributionType"
                    :filter="filterByType"
                    @loading="setBusy"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import BrandMentionsList from './List/BrandMentionsList.vue';
import { MentionMediaTypes } from '.';

import type { Distribution, DistributionType } from '@/types/Report';

type FilterItem = {
    value: string;
    text: string;
};

const BrandMentionsProps = Vue.extend({
    name: 'BrandMentions',
    props: {
        mentions: {
            type: Array as PropType<Distribution[]>,
            default() {
                return [];
            }
        },
        distributionType: {
            type: Object as PropType<DistributionType>,
            default() {
                return null;
            }
        },
        googlePickupUrl: {
            type: String,
            default() {
                return '';
            }
        },
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        BrandMentionsList
    }
})
export default class BrandMentions extends BrandMentionsProps {
    isBusy = false;
    filterByType = '';

    get typeFilterItems() {
        return Object.keys(MentionMediaTypes).reduce(
            (acc: FilterItem[], type: string) => {
                if (this.hasMentionsOfType(type)) {
                    acc.push({
                        value: type,
                        text: MentionMediaTypes[type].label
                    });
                }

                return acc;
            },
            []
        );
    }

    get isFilterable() {
        return this.typeFilterItems.length > 1;
    }

    setBusy(isBusy = true) {
        this.isBusy = isBusy;
    }

    hasMentionsOfType(type: string) {
        // type could be complex
        const types = type.split(',');

        return this.mentions.some(mention => types.includes(mention.type));
    }
}
</script>

<style lang="scss" scoped>
.reduced-size {
    font-size: 75%;
}
</style>
