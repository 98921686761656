<template>
    <v-container>
        <report-top-banner class="mt-4 mb-5" />

        <v-row class="mt-2 mb-0 px-3">
            <v-col
                cols="12"
                md="3"
                order="2"
                order-md="1"
                class="px-0"
                :class="{ 'pt-2': $vuetify.breakpoint.smAndDown }"
            >
                <div
                    class="text-h6 text-md-h5 font-weight-bold"
                    :class="{
                        'text-center pt-3 mb-n2': $vuetify.breakpoint.smAndDown
                    }"
                >
                    Mentions Report
                </div>
            </v-col>
            <v-col
                cols="12"
                md="9"
                order="1"
                order-md="2"
                class="px-0"
                :class="{ 'pa-0': $vuetify.breakpoint.smAndDown }"
            >
                <v-btn
                    color="white"
                    elevation="0"
                    class="back-button"
                    title="Back to Amp"
                    :block="$vuetify.breakpoint.smAndDown"
                    :to="ampLink"
                >
                    <v-icon small left>arrow-left</v-icon>
                    Back to Amp
                </v-btn>
            </v-col>
        </v-row>
        <v-row dense class="report-container">
            <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" md="3">
                <sticky-media
                    relative-element-selector=".report-container"
                    :offset="{ top: 90, bottom: 90 }"
                    :enabled="!$vuetify.breakpoint.mobile"
                >
                    <v-list
                        nav
                        class="main-background pa-0"
                        :class="{ 'pr-4': $vuetify.breakpoint.mdAndUp }"
                    >
                        <v-list-item-group v-model="activeSection">
                            <template v-for="section in sections">
                                <v-menu
                                    v-if="section.sections"
                                    :key="section.to"
                                    min-width="180"
                                    open-on-hover
                                    internal-activator
                                    right
                                    offset-x
                                    nudge-top="8"
                                >
                                    <template #activator="{ on, attrs, value }">
                                        <v-list-item
                                            :dense="
                                                $vuetify.breakpoint.smAndDown
                                            "
                                            class="justify-center"
                                            :class="{ menu__open: value }"
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="action(section)"
                                        >
                                            <!-- eslint-disable vue/no-v-text-v-html-on-component -->
                                            <v-list-item-icon
                                                :class="{
                                                    'mr-3': $vuetify.breakpoint
                                                        .smAndDown,
                                                    'pl-2 mr-4':
                                                        $vuetify.breakpoint
                                                            .mdAndUp
                                                }"
                                                class="justify-center align-center"
                                            >
                                                <v-icon
                                                    small
                                                    style="width: 20px"
                                                    v-text="section.icon"
                                                />
                                            </v-list-item-icon>

                                            <v-list-item-content>
                                                <v-list-item-title
                                                    class="text-h7 text-wrap"
                                                    v-text="section.title"
                                                />
                                            </v-list-item-content>
                                            <!-- eslint-enable vue/no-v-text-v-html-on-component -->
                                        </v-list-item>
                                    </template>
                                    <v-list>
                                        <v-list-item
                                            v-for="subSection in section.sections"
                                            :key="subSection.to"
                                            @click="action(subSection)"
                                        >
                                            <v-list-item-title>
                                                {{ subSection.title }}
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                                <v-list-item
                                    v-else
                                    :key="section.to"
                                    :dense="$vuetify.breakpoint.smAndDown"
                                    class="justify-center"
                                    @click="action(section)"
                                >
                                    <!-- eslint-disable vue/no-v-text-v-html-on-component -->
                                    <v-list-item-icon
                                        :class="{
                                            'mr-3': $vuetify.breakpoint
                                                .smAndDown,
                                            'pl-2 mr-4':
                                                $vuetify.breakpoint.mdAndUp
                                        }"
                                        class="justify-center align-center"
                                    >
                                        <v-icon
                                            small
                                            style="width: 20px"
                                            v-text="section.icon"
                                        />
                                    </v-list-item-icon>

                                    <v-list-item-content>
                                        <v-list-item-title
                                            class="text-h7 text-wrap"
                                            v-text="section.title"
                                        />
                                    </v-list-item-content>
                                    <!-- eslint-enable vue/no-v-text-v-html-on-component -->
                                </v-list-item>
                            </template>
                        </v-list-item-group>
                    </v-list>
                </sticky-media>
            </v-col>
            <v-col
                cols="12"
                md="9"
                class="report-column"
                :class="{ 'mb-14': $vuetify.breakpoint.smAndDown }"
            >
                <distribution-report
                    :report="report"
                    :loading="isLoading"
                    @intersect="onIntersect"
                    @actions="setActions"
                />
                <report-bottom-banner class="mt-4" />
            </v-col>
        </v-row>

        <v-bottom-navigation
            v-if="$vuetify.breakpoint.smAndDown"
            v-model="activeSection"
            fixed
            hide-on-scroll
            scroll-threshold="400"
            class="bottom-navigation"
        >
            <v-btn
                v-for="section in sections"
                :key="section.to"
                @click="action(section)"
            >
                <span class="pt-1">{{ section.alt || section.title }}</span>
                <!-- eslint-disable vue/no-v-text-v-html-on-component -->
                <v-icon small v-text="section.icon" />
                <!-- eslint-enable vue/no-v-text-v-html-on-component -->
            </v-btn>
        </v-bottom-navigation>
    </v-container>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';

import { getScrollParent } from '@/utils/helpers';
import { Endpoint } from '@/mixins';

import { StickyMedia } from '@/components/ASlideshow';
import { DistributionReport } from '@/components/DistributionReport';

import ReportTopBanner from './ReportTopBanner.vue';
import ReportBottomBanner from './ReportBottomBanner.vue';

import type { Report as TReport } from '@/types/Report';

type ReportSection = {
    title: string;
    icon?: string;
    to?: string;
    alt?: string;
    action?: string;
    sections?: ReportSection[];
};

type ReportActions = Record<string, () => Promise<void>>;

@Component({
    components: {
        StickyMedia,
        ReportTopBanner,
        ReportBottomBanner,
        DistributionReport
    }
})
export default class Report extends mixins(Endpoint) {
    report: TReport | null = null;

    sections: ReportSection[] = [
        { title: 'Mentions', icon: 'at', to: '#mentions' },
        { title: 'Keywords', icon: 'key', to: '#keywords' },
        {
            title: 'Download report',
            alt: 'Report', // shorter version to fit the button
            icon: 'download',
            to: '#download',
            sections: [
                {
                    title: 'Download PDF report',
                    action: 'downloadPdf'
                },
                {
                    title: 'Download CSV report',
                    action: 'downloadCsv'
                }
            ]
        },
        { title: 'FAQ', icon: 'far fa-circle-question', to: '#faq' }
    ];

    actions: ReportActions = {};

    activeSection = 0;

    isNavigating = false;

    endpoint = '/announcements/report';

    get announcementId() {
        return this.$route.params.announcementId;
    }

    get sourceUrl() {
        return [this.endpoint, this.announcementId].join('/');
    }

    get ampLink() {
        return `/announcements/review/${this.announcementId}`;
    }

    onData(data: {
        press_release: TReport['press_release'];
        mentions: TReport['mentions'];
        keywords: TReport['keywords'];
        headlines: TReport['headlines'];
    }) {
        if (data && data.mentions) {
            this.report = {
                press_release: data.press_release,
                mentions: data.mentions,
                keywords: data.keywords,
                headlines: data.headlines
            };
        } else {
            this.$router.replace('/404');
        }
    }

    action(section: ReportSection) {
        if (section.action && this.actions[section.action]) {
            return this.actions[section.action]();
        }

        if (section.to) {
            this.scrollTo(section.to);
        }
    }

    scrollTo(href: string) {
        const element = document.getElementById(href.replace('#', ''));

        if (element) {
            const container = getScrollParent(element) as HTMLElement;

            if (container) {
                this.isNavigating = true;

                this.$vuetify
                    .goTo(element, {
                        container,
                        offset: 20
                    })
                    .then(() => {
                        this.isNavigating = false;
                    });
            }
        }
    }

    onIntersect(active: string) {
        if (!this.isNavigating) {
            this.activeSection = this.sections.findIndex(
                section => section.to === `#${active}`
            );
        }
    }

    setActions(actions: ReportActions) {
        this.actions = {
            ...this.actions,
            ...actions
        };
    }
}
</script>

<style lang="scss" scoped>
.back-button {
    color: $primary-color;
}

.report-column {
    z-index: 1;
}

.bottom-navigation {
    z-index: 2;
}

.menu__open::before {
    opacity: 0.04;
}
</style>
