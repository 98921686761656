<template>
    <v-row v-if="loading" class="a-list-row ma-0 mb-1 keyword-loading-row">
        <v-col cols="3" class="pl-2 pr-0 ma-auto">
            <v-skeleton-loader
                type="text"
                :width="randomBetween(25, 75) + '%'"
            />
        </v-col>
        <v-col cols="9" class="pl-2 pr-0 ma-auto">
            <v-skeleton-loader
                type="text"
                :width="randomBetween(25, 75) + '%'"
            />
        </v-col>
    </v-row>
    <v-hover v-else v-slot="{ hover }">
        <v-row
            class="a-list-row ma-0 mb-1 keyword-row"
            :class="{
                white: !hover,
                'concrete-light': hover,
                'px-2': $vuetify.breakpoint.mdAndUp,
                small: $vuetify.breakpoint.smAndDown
            }"
        >
            <v-col cols="3" class="pl-2 pr-0 ma-auto text-center">
                #{{ ranking }}
            </v-col>
            <v-col cols="9" class="pl-2 pr-0 ma-auto d-inline-flex">
                <a class="text-truncate" target="_blank" :href="link">
                    <text-truncated :text="phrase" />
                </a>
            </v-col>
        </v-row>
    </v-hover>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import { randomBetween } from '@/utils/helpers';
import { TextTruncated } from '@/components/TextTruncated';

import type { KeywordRank } from '@/types/Report';

const KeywordRowProps = Vue.extend({
    name: 'KeywordRow',
    props: {
        keyword: {
            type: Object as PropType<Partial<KeywordRank>>,
            default(): KeywordRank | null {
                return null;
            }
        },
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        TextTruncated
    }
})
export default class KeywordRow extends KeywordRowProps {
    get ranking() {
        return this.keyword?.position || '';
    }

    get phrase() {
        return this.keyword?.keyword || '';
    }

    get link() {
        return this.keyword
            ? `https://www.google.com/search?q=${this.keyword.keyword}`
            : '';
    }

    randomBetween = randomBetween;
}
</script>

<style lang="scss">
@import '@/components/AList/AList.scss';

.keyword-loading-row {
    padding-top: 6px;
}

.keyword-row {
    box-shadow: 0px 5px 10px -5px $grey-medium-light;

    &.small {
        font-size: 80%;
    }
}
</style>
