<template>
    <v-container class="pa-0">
        <v-row>
            <v-col class="text-center pb-6">
                <label class="text-h6">Frequently Asked Questions</label>
            </v-col>
        </v-row>
        <v-row v-for="(faq, index) in questions" :key="index">
            <v-col class="pa-0 mb-2">
                <v-container class="content">
                    <v-row>
                        <v-col class="pa-1">
                            <v-expansion-panels flat accordion>
                                <v-expansion-panel>
                                    <v-expansion-panel-header
                                        class="font-weight-medium text-subtitle-1 py-0"
                                    >
                                        {{ faq.question }}
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content
                                        class="text-body-2"
                                    >
                                        <!-- eslint-disable-next-line vue/no-v-html -->
                                        <span v-html="faq.answer"></span>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import type { ReportQuestion } from '@/types/Report';

const ReportFaqProps = Vue.extend({
    name: 'ReportFaq',
    props: {
        questions: {
            type: Array as PropType<ReportQuestion[]>,
            default() {
                return [];
            }
        }
    }
});

@Component
export default class ReportFaq extends ReportFaqProps {}
</script>

<style lang="scss" scoped>
.content {
    background-color: $white;
    border-radius: 8px;
    box-shadow: 0px 5px 10px -5px $grey-medium-light;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
    .content {
        left: 10px;
        width: calc(100% - 20px);
    }
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
    .content {
        left: 25px;
        width: calc(100% - 50px);
    }
}
</style>
