<template>
    <v-container :class="{ 'pa-0': $vuetify.breakpoint.smAndDown }">
        <v-row>
            <v-col class="px-6">
                <div class="report-banner">
                    <v-container>
                        <v-row
                            class="flex-row-reverse"
                            :class="{
                                ' justify-center': $vuetify.breakpoint.smAndDown
                            }"
                        >
                            <v-col
                                cols="10"
                                md="4"
                                class="banner-action d-flex justify-center align-center flex-column text-center pa-4"
                            >
                                <span class="text-subtitle-1">
                                    Professional Amp Authors are available to
                                    create your next amp
                                </span>
                                <v-btn
                                    class="mt-3 primary--text"
                                    color="white"
                                    elevation="0"
                                    title="Learn More"
                                    to="/author-requests/create"
                                >
                                    Learn More
                                    <v-icon small right>arrow-right</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class ReportTopBanner extends Vue {}
</script>

<style lang="scss" scoped>
.report-banner::v-deep {
    position: relative;
    border-radius: 8px;
    background-position: left center;
    min-height: 200px;
    background-repeat: no-repeat;
    background-image: linear-gradient(90deg, #ece9e6 0%, #ffffff 100%);

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url('@/assets/img/report-banner/bottom-art.svg');
        background-position: left center;
        border-radius: 8px;
    }

    .banner-action {
        min-height: 200px;
    }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
    .report-banner::before {
        background-size: 55%;
        background-position: bottom left;
    }
}
</style>
