<template>
    <vue-word-cloud
        v-if="!loading"
        :words="limitedWords"
        style="height: 400px; width: 100%"
        font-family="DM Sans"
        :spacing="0.5"
        :font-size-ratio="0.6"
        animation-easing="ease"
        :animation-duration="3000"
        :animation-overlap="0.2"
        @update:progress="onProgress"
    >
        <template #default="{ text }">
            <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                    <div
                        v-bind="attrs"
                        class="keyword"
                        v-on="on"
                        @click="onWordClick(text)"
                    >
                        {{ text }}
                    </div>
                </template>
                #{{ getWordPosition(text) }} {{ text }}
            </v-tooltip>
        </template>
    </vue-word-cloud>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import VueWordCloud from 'vuewordcloud';

import type { KeywordRank, Report } from '@/types/Report';

type KeywordGroup = {
    words: string[];
    condition: (word: KeywordRank) => boolean;
};

type WeightedWord = {
    text: string;
    weight: number;
    color: string;
    fontWeight: number;
};

const COLORS = ['#2b478b', '#fe0d66', '#3591fb', '#25ced1'];

const KeywordsCloudProps = Vue.extend({
    name: 'KeywordsCloud',
    props: {
        keywords: {
            type: Object as PropType<Report['keywords']>,
            default(): Report['keywords'] {
                return {};
            }
        },
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        },
        limit: {
            type: [String, Number],
            default() {
                return 20;
            }
        },
        group: {
            type: [String, Number],
            default() {
                return 5;
            }
        }
    }
});

@Component({
    components: {
        VueWordCloud
    }
})
export default class KeywordsCloud extends KeywordsCloudProps {
    renderAll = false;

    get words() {
        return this.getGroups().reduce(
            (acc: WeightedWord[], group, groupIdx) => {
                acc.push(
                    ...group.words.map(word => {
                        return {
                            text: word,
                            weight: (groupIdx + 1) * -1,
                            color: COLORS[
                                Math.floor(Math.random() * COLORS.length)
                            ],
                            fontWeight: groupIdx < 1 ? 700 : 400
                        } as WeightedWord;
                    })
                );

                return acc;
            },
            []
        );
    }

    get allWords() {
        return Object.keys(this.keywords || {}).sort(
            (a, b) => a.length - b.length
        );
    }

    get limitedWords() {
        return this.words.slice(0, Number(this.limit));
    }

    getGroups() {
        const source = this.allWords;

        const groups: KeywordGroup[] = [
            {
                words: [],
                condition: word => word.position === 1
            },
            {
                words: [],
                condition: word => word.position <= 5
            },
            {
                words: [],
                condition: word => word.position >= 5 && word.position <= 10
            },
            {
                words: [],
                condition: word => word.position > 10
            }
        ];

        groups.forEach(group => {
            while (group.words.length < +this.group && source.length) {
                const match = source.findIndex(w =>
                    group.condition(this.keywords[w])
                );

                if (~match) {
                    group.words.push(...source.splice(match, 1));
                } else {
                    break;
                }
            }
        });

        if (source.length) {
            groups.push({
                words: source.sort(
                    (a, b) =>
                        this.keywords[b].position - this.keywords[a].position
                ),
                condition: () => true
            });
        }

        return groups;
    }

    getWordPosition(word: string) {
        const keyword = this.keywords[word];

        return keyword.position;
    }

    onProgress(
        progress: {
            completedWords: number;
            totalWords: number;
        } | null
    ) {
        if (progress) {
            const { completedWords, totalWords } = progress;

            this.$emit('progress', (completedWords * 100) / totalWords);
        }
    }

    onWordClick(word: string) {
        this.$emit('select', word);
    }
}
</script>

<style lang="scss" scoped>
.keyword {
    cursor: pointer;
}
</style>
