import { VCol } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.loading)?_c(VRow,{staticClass:"a-list-row ma-0 mb-1 keyword-loading-row"},[_c(VCol,{staticClass:"pl-2 pr-0 ma-auto",attrs:{"cols":"3"}},[_c(VSkeletonLoader,{attrs:{"type":"text","width":_vm.randomBetween(25, 75) + '%'}})],1),_c(VCol,{staticClass:"pl-2 pr-0 ma-auto",attrs:{"cols":"9"}},[_c(VSkeletonLoader,{attrs:{"type":"text","width":_vm.randomBetween(25, 75) + '%'}})],1)],1):_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c(VRow,{staticClass:"a-list-row ma-0 mb-1 keyword-row",class:{
            white: !hover,
            'concrete-light': hover,
            'px-2': _vm.$vuetify.breakpoint.mdAndUp,
            small: _vm.$vuetify.breakpoint.smAndDown
        }},[_c(VCol,{staticClass:"pl-2 pr-0 ma-auto text-center",attrs:{"cols":"3"}},[_vm._v(" #"+_vm._s(_vm.ranking)+" ")]),_c(VCol,{staticClass:"pl-2 pr-0 ma-auto d-inline-flex",attrs:{"cols":"9"}},[_c('a',{staticClass:"text-truncate",attrs:{"target":"_blank","href":_vm.link}},[_c('text-truncated',{attrs:{"text":_vm.phrase}})],1)])],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }