<template>
    <v-container :class="{ 'pa-0': $vuetify.breakpoint.smAndDown }">
        <v-row justify="center">
            <v-col cols="auto">
                <v-btn
                    color="primary"
                    :loading="isDownloading"
                    @click="downloadPdf"
                >
                    <v-icon left small>file-pdf</v-icon>
                    Download PDF Report
                </v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn :loading="isDownloading" @click="downloadCsv">
                    <v-icon left small>file-csv</v-icon>
                    Download CSV Report
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import type { Company } from '@/types/Company';

const DownloadReportProps = Vue.extend({
    name: 'DownloadReport',
    props: {
        company: {
            type: Object as PropType<Company>,
            default() {
                return {
                    name: ''
                };
            }
        }
    }
});

@Component
export default class DownloadReport extends DownloadReportProps {
    isDownloading = false;

    get announcementId() {
        return this.$route.params.announcementId;
    }

    get link() {
        return `/announcements/report_pdf/${this.announcementId}`;
    }

    get linkCsv() {
        return `/announcements/report_csv/${this.announcementId}`;
    }

    get filename() {
        return [
            'Distribution',
            'Report',
            ...this.company.name.split(' ').filter(Boolean),
            this.announcementId,
            this.$date().format('DD-MM-YYYY_HH-mm-ss')
        ]
            .filter(Boolean)
            .join('_')
            .replace(/[./\\:^]/g, '');
    }

    downloadPdf() {
        return this.download();
    }

    downloadCsv() {
        return this.download(this.linkCsv);
    }

    async download(link: string = this.link) {
        this.setLoading();

        const file = await fetch(link);

        const blob = await file.blob();

        const href = URL.createObjectURL(blob);

        Object.assign(
            document.createElementNS('http://www.w3.org/1999/xhtml', 'a'),
            {
                target: '_blank',
                rel: 'noopener noreferrer',
                download: this.filename,
                href
            }
        ).click();

        setTimeout(function () {
            URL.revokeObjectURL(href);
        }, 4e4); // 40s

        this.setLoading(false);
    }

    setLoading(isLoading = true) {
        this.isDownloading = isLoading;

        this.$store.dispatch(isLoading ? 'loading/show' : 'loading/hide');
    }
}
</script>
