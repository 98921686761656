import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"pa-0"},[_c(VRow,[_c(VCol,{staticClass:"text-center pb-6"},[_c('label',{staticClass:"text-h6"},[_vm._v("Frequently Asked Questions")])])],1),_vm._l((_vm.questions),function(faq,index){return _c(VRow,{key:index},[_c(VCol,{staticClass:"pa-0 mb-2"},[_c(VContainer,{staticClass:"content"},[_c(VRow,[_c(VCol,{staticClass:"pa-1"},[_c(VExpansionPanels,{attrs:{"flat":"","accordion":""}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,{staticClass:"font-weight-medium text-subtitle-1 py-0"},[_vm._v(" "+_vm._s(faq.question)+" ")]),_c(VExpansionPanelContent,{staticClass:"text-body-2"},[_c('span',{domProps:{"innerHTML":_vm._s(faq.answer)}})])],1)],1)],1)],1)],1)],1)],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }